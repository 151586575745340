
<script>
export default {
  created() {
    this.$store
      .dispatch('logout')
      .then(() => {
        this.$router.push({ name: 'Login' });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
